@font-face {
  font-family: "proxima-nova";
  src: url(fonts/proximanova-regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url(fonts/proximanova-bold.woff);
  font-weight: bold;
}

@font-face {
  font-family: "proxima-nova";
  src: url(fonts/proximanova-light.woff);
  font-weight: lighter;
}
.btn-primary {
  border-color: transparent;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #d0021b;
  white-space: initial;
}
.has-error .form-control {
  border-color: #d0021b;
}
body {
  * {
    letter-spacing: 0.5px;
  }
  button,
  a,
  input {
    outline: none !important;
  }
  .is-flex.row {
    flex-wrap: nowrap !important;
  }
}

span.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;

  &.check {
    background-image: url(/assets/ic_done.svg);
    padding: 12px;
  }
}

.row.is-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: relative;

  > [class*="col-"] {
    flex-direction: column;
  }
}

// Tabs
ul.nav.nav-tabs {
  li {
    font-size: 20px;
    font-weight: bold;

    > a {
      margin-right: 0;
      background: #e8ebed;
      color: #0e2439;
    }

    &.active {
      > a {
        background: #fff;
      }
    }
  }

  li:first-child.active {
    > a:after {
      content: "";
      width: 0;
      height: 0;
      border-bottom: 48px solid #cdd7e2;
      border-right: 23px solid transparent;
      position: absolute;
      top: 0;
      right: -23px;
      z-index: 100;
    }
  }

  li:last-child.active {
    > a:before {
      content: "";
      width: 0;
      height: 0;
      border-bottom: 48px solid #cdd7e2;
      border-left: 23px solid transparent;
      position: absolute;
      top: 0;
      left: -23px;
      z-index: 100;
    }
  }
}

// back-link

.back-link {
  color: #0e2439;
  margin-left: 10px;

  &:before {
    content: "";
    display: inline-block;
    padding: 5px 7px 3px 7px;
    margin-left: 4px;
    margin-right: 10px;
    background-image: url(/assets/ic_arrow_down.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }
}

// Custom btns

.btn-tertiary {
  background: none;
  box-shadow: 0 0 0 2px rgba(198, 170, 117, 1) inset;
  color: #c6aa76;

  &:hover,
  :focus {
    background: none;
    box-shadow: 0 0 0 6px rgba(198, 170, 117, 1) inset;
    color: #c6aa76;
  }

  &.active {
    background: #c6aa76;
    color: #fff;
  }
}

// Modal

.modal {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;

  .modal-body {
    text-align: center;
    padding: 30px 15px 70px;
    font-size: 18px;

    .help-block {
      font-size: 14px;
      white-space: initial;
      text-align: left;
    }

    .form-group-lg {
      margin-top: 10px;
    }
  }

  .modal-footer {
    background-color: #fff;
    text-align: center;

    .btn {
      min-width: 85px;
    }

    .btn + .btn {
      margin-left: 15px;
    }
  }
}

// Close
.close {
  font-family: "proxima-nova", sans-serif;
  line-height: 19px;
  font-size: 23px;
  opacity: 0.7;
  text-align: center;

  &:hover {
    opacity: 1;
  }
}

button {
  &.close {
    height: 20px;
    width: 20px;
    border: 2px solid #c6aa76;
    border-radius: 50%;
  }
}

// buttons

.btn {
  border-width: 2px;
  font-family: "Open Sans", sans-serif;

  &.btn-default {
    &:focus {
      color: #c6aa76;
    }

    &:hover {
      color: #c6aa76;
    }

    &:active {
      color: #fff;
      background: #c6aa76;
    }
  }

  &.btn-secondary {
    color: #fff;

    background-color: #13447c;

    &:hover {
      background-color: #165aa8;
    }

    &:focus,
    &:active {
      background-color: #0e2439;
    }
  }
}

.btn-lg {
  font-size: 18px;
}

// dobfields

.dobfields {
  label.control-label {
    display: block;
    width: 100%;
  }

  .dobfields-inner {
    width: 50%;
    float: left;

    &:nth-child(2) {
      padding-right: 5px;
    }

    &:last-child {
      padding-left: 5px;
    }
  }
}

//

.birthdayfields {
  label.control-label {
    display: block;
    width: 100%;
  }

  .birthdayfields-inner {
    width: calc(100% / 3);
    float: left;

    &:first-child {
      padding-right: 5px;
    }

    &:nth-child(2) {
      padding-right: 5px;
      padding-left: 5px;
    }

    &:last-child {
      padding-left: 5px;
    }
  }

  .help-block {
    display: inline;
  }
}

.help-block {
  white-space: nowrap;
}

.confirmation-modal {
  .modal-content {
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    .modal-dialog {
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
    }
  }
}

.modal-backdrop {
  opacity: 0 !important;
}

.table {
  thead {
    background-color: #f0f0f0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "proxima-nova", sans-serif !important;
}

h1 {
  font-size: 50px;
  font-weight: normal;
}

h2 {
  font-size: 30px;
  font-weight: bold;
}

h3 {
  font-size: 26px;
  font-weight: bold;
}

h4 {
  font-size: 22px;
  font-weight: bold;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}

h6 {
  font-size: 14px;
  font-weight: bold;
}

.disabled {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  p,
  table thead th,
  .search-for-the-medic {
    color: #b6c6d6 !important;
  }

  a {
    color: #bcebfa;
    text-decoration: none;
  }
}

.content-page {
  .navbar.navbar-default {
    &.navbar-home {
      background-color: #0e2439 !important;
    }
    border-bottom: 9px solid #c6aa76;
  }

  > div {
    .content-page-header {
      margin-top: 100px;
      padding: 80px 0;
      background: #0e2439;
      background: url(/assets/blue_woman.jpg);
      h3 {
        color: #fff;
      }
    }

    .container {
      margin-top: 30px;
      margin-bottom: 40px;
      min-height: calc(100vh - 745.7px);

      p,
      ul > li {
        font-size: 18px;
      }
    }
  }
}

.confirmation-sent {
  padding-top: 20px;

  &:before {
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/assets/ic_drafts.svg);
    padding: 0 10px;
    height: 19px;
    margin-right: 10px;
  }
}

.droice-search-error {
  width: 100%;
}
